<template>
  <div class="uploadzip">
    <el-upload
      class="upload-demo"
      drag
      action=""
      multiple
      :before-upload="beforeupload"
      :http-request="uploadhttp"
      :before-remove="removes"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">
        只能上传压缩包文件，且不超过50M！
      </div>
    </el-upload>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userinfo:{},
      name:"",
    };
  },
  methods: {
    uploadhttp(e) {
      var that=this;
      let file = new FormData(); 
      file.append("file", e.file);
      this.name=e.file.name;
      this.$axios({
        headers: {
          "Content-Type": "application/x-www-form-urlencoded","X-CSRF-TOKEN": that.userinfo.token,
        },
        // responseType:"blob",
        url:"/api/UploadFile/upZip",
        method: "post",
        data:file,
      }).then((res) => {
        console.log(res.data)
        if(res.data.code==1){
          var datas={
            src:res.data.result,
            name:that.name,
          }
          this.$emit('uploadzip',datas);
          this.$message.success(res.data.messages);
        }
      });
      
    },
    beforeupload(file) {
      var that = this;
      console.log(file);
      let isLt1M = file.size / 1024 / 1024 < 50;
      if (!isLt1M) {
        that.$message.error("上传文件大小不能超过 50MB!");
        return isLt1M;
      }
      return  isLt1M;
    },
    removes(file, fileList){
      // console.log(file,fileList);
      // return false;
    }
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
  },

};
</script>