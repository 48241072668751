<template>
  <div class="Userhl_faxiangmu">
    <div v-if="xiangmutype">
      <div class="fxiangmu_top">
        <ul class="fxiangmu_topul">
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">任务名称</div>
            <div class="fxiangmu_toplir">
              <el-input
                v-model="xm_name"
                placeholder="请输入任务名称"
              ></el-input>
            </div>
          </li>
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">设计类型</div>
            <div class="fxiangmu_toplir fxiangmu_toplirtypeb">
              <div class="fxiangmu_toplirtypebbox" @click="typechange(22)">
                <div :class="bigbk22">
                  <img
                    src="https://img.hozoin.cn/icon/class/hj.png"
                    alt=""
                    v-show="bigbk22_t"
                  />
                  <img
                    src="https://img.hozoin.cn/icon/class/hj_file.png"
                    alt=""
                    v-show="bigbk22_f"
                  />
                </div>
                <p>环境建筑设计</p>
              </div>
              <div class="fxiangmu_toplirtypebbox" @click="typechange(23)">
                <div :class="bigbk23">
                  <img
                    src="https://img.hozoin.cn/icon/class/gy.png"
                    alt=""
                    v-show="bigbk23_t"
                  />
                  <img
                    src="https://img.hozoin.cn/icon/class/gy_file.png"
                    alt=""
                    v-show="bigbk23_f"
                  />
                </div>
                <p>工业产品设计</p>
              </div>
              <div class="fxiangmu_toplirtypebbox" @click="typechange(24)">
                <div :class="bigbk24">
                  <img
                    src="https://img.hozoin.cn/icon/class/sj.png"
                    alt=""
                    v-show="bigbk24_t"
                  />
                  <img
                    src="https://img.hozoin.cn/icon/class/sj_file.png"
                    alt=""
                    v-show="bigbk24_f"
                  />
                </div>

                <p>视觉传达设计</p>
              </div>
              <div class="fxiangmu_toplirtypebbox" @click="typechange('1000')">
                <div :class="bigbk">
                  <img
                    src="https://img.hozoin.cn/icon/class/qt.png"
                    alt=""
                    v-show="bigbk_t"
                  />
                  <img
                    src="https://img.hozoin.cn/icon/class/qt_file.png"
                    alt=""
                    v-show="bigbk_f"
                  />
                </div>

                <p>其他</p>
              </div>
            </div>
          </li>
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">专业细分</div>
            <div class="fxiangmu_toplir fxiangmu_toplirtypes">
              <span>全部</span>
              <span
                v-for="(item, index) in typesmall"
                :key="index"
                @click="typesmallid(item.id, item.icon)"
                >{{ item.title }}</span
              >
            </div>
          </li>
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">上传封面</div>
            <div class="fxiangmu_toplir fxiangmu_toplirimgbtn">
              <img :src="fmimg" alt="" />
              <el-button plain icon="el-icon-picture">
                上传封面
                <Uploadimg @uploadimg="uploadimgurl" :imgall="imgalls" />
              </el-button>
            </div>
          </li>
        </ul>
      </div>
      <div class="fxiangmu_top">
        <ul class="fxiangmu_topul">
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">需求阶段</div>
            <div class="fxiangmu_toplir">
              <el-select v-model="xm_xuxiutype" placeholder="请选择需求阶段">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </li>
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">报名时间范围</div>
            <div class="fxiangmu_toplir fxiangmu_toplir_datebox">
              <div class="fxiangmu_toplir_date">
                <el-date-picker
                  v-model="startend"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
              <!-- <div class="fxiangmu_toplir_datetext">期望上市日期</div>
              <div class="fxiangmu_toplir_date">
                <el-date-picker
                  v-model="xm_shangshi"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div> -->
            </div>
          </li>
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">调研决策期限</div>
            <div class="fxiangmu_toplir fxiangmu_toplir_datebox">
              <div class="fxiangmu_toplir_date">
                <div class="fxiangmu_toplir_date">
                  <el-input
                    v-model="xm_dy"
                    placeholder="请输入调研决策期限天数"
                  ></el-input>
                </div>
              </div>
              <div class="fxiangmu_toplir_datetext">初步期限</div>
              <div class="fxiangmu_toplir_date">
                <el-input
                  v-model="xm_chubu"
                  placeholder="请输入初步期限天数"
                ></el-input>
              </div>
            </div>
          </li>
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">项目概述</div>
            <div class="fxiangmu_toplir">
              <el-input
                type="input"
                autosize
                placeholder="请输入项目概述"
                v-model="xm_gaishu"
              >
              </el-input>
            </div>
          </li>
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">需求描述</div>
            <div class="fxiangmu_toplir fxiangmu_toplir_rwsbtnbox">
              <div class="fxiangmu_toplir_rwsbtn">
                <el-button plain icon="el-icon-edit" @click="rwsbtn">{{
                  renwushutext
                }}</el-button>
              </div>
              <div class="fxiangmu_toplir_rwsbtntext">
                （为保证设计师能够准确理解您的设计目标，保障设计质量，请认真填写设计任务书）
              </div>
            </div>
          </li>
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">上传附件</div>
            <div class="fxiangmu_toplir fxiangmu_toplir_rwsbtnbox">
              <div v-if="uploadziptype">
                <Uploadzip @uploadzip="uploadzipurl" />
              </div>
              <div v-if="uploadziptype_f">
                <el-button disabled>上传完成！</el-button>
                <el-button plain @click="uploadzipurl_again"
                  >新增附件</el-button
                >
              </div>
              <div class="fujianallbox">
                <el-button
                  v-for="(item, index) in uploadzipurls"
                  :key="index"
                  >{{ item.name }}</el-button
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="fxiangmu_top">
        <ul class="fxiangmu_topul">
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">项目金额</div>
            <div class="fxiangmu_toplir fxiangmu_toplir_zhiding">
              <el-input
                v-model="xm_money"
                placeholder="请输入项目金额"
                class="xm_moneyallcss"
              ></el-input>
              <span>平台服务费20%</span>
              <span>￥{{ fuwumoney }}</span>
            </div>
          </li>
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div
              class="fxiangmu_toplic"
              style="display: block; line-height: 4.5rem"
            >
              <el-switch
                v-model="zhiding"
                active-color="#13ce66"
                inactive-color="#eee"
                style="margin-right: 1rem"
              >
              </el-switch>
              <span>置顶</span>
            </div>
            <div class="fxiangmu_toplir fxiangmu_toplir_zhiding">
              <span>￥{{ zhiding_fei }}.00/天</span>
              <span>
                <el-input
                  v-model="zhiding_num"
                  placeholder="请输入置顶天数"
                ></el-input>
              </span>
              <span>天</span>
              <span>项目置顶费用</span>
              <span>￥{{ zhidingmoney }}</span>
            </div>
          </li>
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">项目总费用</div>
            <div class="fxiangmu_toplir fxiangmu_toplir_moneyall">
              ￥{{ moneyall }}
            </div>
          </li>
        </ul>
      </div>
      <div class="fxiangmu_top">
        <ul class="fxiangmu_topul">
          <li class="fxiangmu_topli">
            <div class="fxiangmu_toplil">*</div>
            <div class="fxiangmu_toplic">是否委托项目</div>
            <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
              <div class="fxiangmu_toplir_weituobox">
                <el-radio v-model="radiowt" label="1">是</el-radio>
              </div>
              <div class="fxiangmu_toplir_weituobox">
                <el-radio v-model="radiowt" label="2">否</el-radio>
              </div>
              <div v-if="wt_usernametype" class="wt_username">
                已选择 {{ wt_username }} 设计师
              </div>
            </div>
          </li>
        </ul>
        <div class="fxiangmu_topul fxiangmu_topul_sousuo" v-show="wttype">
          <el-input
            placeholder="请输入委托设计师用户名进行搜索"
            v-model="wt_mohu"
          >
            <template slot="append">
              <el-button icon="el-icon-search" @click="sousuowt"></el-button>
            </template>
          </el-input>
        </div>
        <ul class="fxiangmu_topul fxiangmu_topul_sousuoul" v-show="wttype">
          <li
            class="fxiangmu_topul_sousuoli"
            v-for="(item, index) in wtuserall"
            :key="index"
            @click="wt_xuanze(item.username, item.userid)"
            :class="item.userid == null ? 'wtuseralllizero' : ''"
          >
            <div class="fxiangmu_topul_sousuoliimg">
              <img :src="item.userpic" alt="" />
            </div>
            <p>{{ item.username }} - 女</p>
            <p>上海工程技术大学 - 工业设计</p>
            <p>
              <span>项目10</span>
              <span>|</span>
              <span>作品10</span>
            </p>
            <ul>
              <li>包装设计</li>
              <li>产品设计</li>
              <li>工业设计</li>
              <li>视觉传达设计</li>
              <li>包装设计</li>
            </ul>
          </li>
        </ul>
      </div>
      <el-button class="xiangmusub_btn" type="primary" plain @click="subxiangmu"
        >发布任务</el-button
      >
    </div>
    <!-- ////////        任务书              //////// -->
    <div class="Userhl_faxiangmutext" v-if="renwushutype">
      <p class="Userhl_faxiangmutext_t">在线填写任务书</p>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in dataall"
          :key="index"
          :label="item.one"
          :name="item.one"
          disabled
        >
          <div class="Userhl_faxiangmutextbox">
            <div class="fxiangmu_top">
              <ul class="fxiangmu_topul">
                <li
                  class="fxiangmu_topli"
                  v-for="(one, index) in item.two"
                  :key="index"
                  v-show="one.input_type != 3"
                >
                  <div class="fxiangmu_toplil">*</div>
                  <div class="fxiangmu_toplic">{{ one.title }}</div>
                  <div class="fxiangmu_toplir">
                    <!-- /////////////// -->
                    <el-input
                      v-if="one.input_type == 1 ? true : false"
                      v-model="one.zhi"
                      :placeholder="'请输入' + one.title"
                    ></el-input>
                    <!-- ////////////// -->
                    <el-input
                      v-if="one.input_type == 2 ? true : false"
                      type="textarea"
                      autosize
                      :placeholder="'请输入' + one.title"
                      v-model="one.zhi"
                    >
                    </el-input>
                    <!-- /////////////////// -->
                    <ul
                      class="fxiangmu_topli0box"
                      v-if="one.input_type == 0 ? true : false"
                    >
                      <li v-for="(two, index) in one.three" :key="index">
                        <div class="fxiangmu_topli0boxlidiv1">
                          {{ two.title }}
                        </div>
                        <div
                          class="fxiangmu_toplir_weituobox"
                          v-if="two.input_type == '4'"
                        >
                          <el-checkbox-group v-model="two.zhi">
                            <el-checkbox
                              v-for="(three, index) in two.four"
                              :key="index"
                              :label="three.title"
                            ></el-checkbox>
                          </el-checkbox-group>
                        </div>
                        <div
                          class="fxiangmu_toplir_weituobox"
                          v-if="two.input_type == '0'"
                        >
                          <div>
                            <el-radio
                              v-for="(three, index) in two.four"
                              :key="index"
                              v-model="two.zhi"
                              :label="three.title"
                              >{{ three.title }}</el-radio
                            >
                          </div>
                        </div>
                        <div
                          class="fxiangmu_toplir_weituobox"
                          style="width:auto;"
                          v-if="two.input_type == '1'"
                        >
                          <el-input
                            v-model="two.zhi"
                            placeholder="请输入"
                          ></el-input>
                        </div>
                      </li>
                    </ul>
                    <!-- /////////////////// -->
                    <div
                      class="fxiangmu_toplir_rwsbtnbox"
                      v-if="one.input_type == 3 ? false : false"
                    >
                      <div v-if="uploadziptype_rw">
                        <Uploadzip @uploadzip="uploadzipurlrenwushu" />
                      </div>
                      <div v-if="uploadziptype_f_rw">
                        <el-button disabled>上传完成！</el-button>
                        <el-button plain @click="uploadzipurl_again"
                          >重新上传附件</el-button
                        >
                        <input type="hidden" v-model="one.zhi" ref="fujian" />
                      </div>
                    </div>
                    <!-- /////////////////// -->
                    <div
                      class="fxiangmu_toplir_weituo"
                      v-show="one.input_type == 4 ? true : false"
                    >
                      <div
                        class="fxiangmu_toplir_weituobox"
                        v-for="(two, index) in one.three"
                        :key="index"
                      >
                        <el-radio v-model="one.zhi" :label="index">{{
                          two.title
                        }}</el-radio>
                      </div>
                    </div>
                    <!-- ///////////// -->
                  </div>
                </li>
              </ul>
              <div class="nextpage">
                <el-button
                  v-if="prvepagetype"
                  plain
                  @click="prvepage(nextpagenum)"
                  >上一页</el-button
                >
                <el-button plain @click="nextpage(nextpagenum)">{{
                  nextpagetext
                }}</el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <zhifu :all="zhifuall" />
  </div>
</template>
<script>
import $ from "jquery";
$(document).on("click", ".fxiangmu_toplirtypes span", function () {
  var eq = $(this).index();
  $(this).css({
    color: "#4FC6FF",
    "border-bottom": "2px solid #4FC6FF",
  });
  $(this).siblings().css({
    color: "#333",
    "border-bottom": "none",
  });
});
$(document).on("click", ".fxiangmu_toplirtypebbox", function () {
  var eq = $(this).index();
  $(this).children("p").css({
    color: "#4FC6FF",
  });
  $(this).siblings().children("p").css({
    color: "#333",
  });
  $(".fxiangmu_toplirtypes span:nth-child(1)").css({
    color: "#4FC6FF",
  });
  $(".fxiangmu_toplirtypes span:nth-child(1)").siblings().css({
    color: "#333",
  });
});
$(document).on("click", ".fxiangmu_topul_sousuoli", function () {
  var eq = $(this).index();
  $(this).css({
    "box-shadow": "0 2px 12px 0  rgba(235, 158, 158, 0.8)",
  });
  $(this).siblings().css({
    "box-shadow": "0 2px 12px 0 rgba(0, 0, 0, 0.2)",
  });
});
import Uploadimg from "@/components/Uploadimg.vue";
import Uploadzip from "../Uploadzip_xm";
import zhifu from "../zhifu.vue";
export default {
  components: {
    Uploadimg,
    Uploadzip,
    zhifu,
  },
  data() {
    return {
      imgalls: {
        name: "",
        width: 400,
        height: 308,
      },
      renwushutext: "填写任务书",
      bigbk: "",
      bigbk_t: true,
      bigbk_f: false,
      bigbk22: "",
      bigbk22_t: false,
      bigbk22_f: true,
      bigbk23: "",
      bigbk23_t: true,
      bigbk23_f: false,
      bigbk24: "",
      bigbk24_t: true,
      bigbk24_f: false,
      zhifuall: {
        type: false,
        money: "",
        ddnnum: "",
        class_id: "1",
        faxiangmu: "88",
      },
      alipay: {},
      yueval: "",
      aliurl: "https://192.168.0.184/api/aliPay/",
      newalli: "",
      fuwumoney: "0",
      ///////////////。

      xm_name: "", //项目名称
      uploadzipurls: [], //附件
      moneyall: 0, //项目总金额
      fmimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png", //项目封面
      typebigidnum: "", //项目大类id
      typesmallidnum: "", //项目细分id
      xm_xuxiutype: "1", //需求阶段
      startend: "", //报名时间范围
      xm_shangshi: "", //期望上市时间
      xm_gaishu: "", //项目概述
      wt_userid: "", //委托设计师id
      undertake: 0, //是否委托 0 false  1 true
      xm_chubu: "", //初步期限
      xm_dy: "", //调研决策期限

      ////////////////
      xiangmutype: true,
      wt_usernametype: false,
      wt_mohu: "",
      input: "",
      wt_username: "",
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      typesmall: [],
      wtuserall: [],
      options: [
        {
          value: "1",
          label: "开发设计",
        },
        {
          value: "2",
          label: "迭代设计",
        },
      ],
      value: "",
      value1: "",
      radiowt: "1", //是否委托设计师
      uploadziptype: true,
      uploadziptype_f: false,
      xm_money: 0,
      zhiding: false,
      zhidingtypenum: 0, //1置顶 0不置顶
      zhiding_num: 0, //置顶天数
      zhidingmoney: 0,
      zhiding_fei: 20,
      wttype: true,
      ///////////////////////   任务书   /////////////////////////////
      renwushutype: false,
      activeName: "",
      dataall: {},
      radio: "1",
      nextpagenum: 1,
      nextpagetext: "下一页",
      prvepagetype: false,
      uploadziptype_rw: true,
      uploadziptype_f_rw: false,
      demand_id: 0, //任务书id
      timer: null,
      userinfo: {},
      ddnnumxm: "",
    };
  },
  methods: {
    rwsbtn() {
      this.xiangmutype = false;
      this.renwushutype = true;
    },
    typechange(id) {
      var that = this;
      this.renwushuaxiso(id);
      if (id != 1000) {
        if (id == 22) {
          that.bigbk = "";
          that.bigbk22 = "bigbk";
          that.bigbk23 = "";
          that.bigbk24 = "";
          that.bigbk_t = true;
          that.bigbk_f = false;
          that.bigbk22_t = false;
          that.bigbk22_f = true;
          that.bigbk23_t = true;
          that.bigbk23_f = false;
          that.bigbk24_t = true;
          that.bigbk24_f = false;
        } else if (id == 23) {
          that.typesmall = [];
          that.bigbk = "";
          that.bigbk22 = "";
          that.bigbk23 = "bigbk";
          that.bigbk24 = "";
          that.bigbk_t = true;
          that.bigbk_f = false;
          that.bigbk22_t = true;
          that.bigbk22_f = false;
          that.bigbk23_t = false;
          that.bigbk23_f = true;
          that.bigbk24_t = true;
          that.bigbk24_f = false;
        } else if (id == 24) {
          that.typesmall = [];
          that.bigbk = "";
          that.bigbk22 = "";
          that.bigbk23 = "";
          that.bigbk24 = "bigbk";
          that.bigbk_t = true;
          that.bigbk_f = false;
          that.bigbk22_t = true;
          that.bigbk22_f = false;
          that.bigbk23_t = true;
          that.bigbk23_f = false;
          that.bigbk24_t = false;
          that.bigbk24_f = true;
        }
        that.typebigidnum = id;
        that
          .$axios({
            headers: { "X-CSRF-TOKEN": that.userinfo.token },
            url: "/api/getClassList",
            method: "post",
            data: {
              class: id,
              tag: 1,
            },
          })
          .then((res) => {
            if (res.data.code == 1) {
              that.typesmall = res.data.result;
            } else {
              that.$message(res.data.messages);
            }
          });
      } else {
        that.typesmall = [];
        that.bigbk = "bigbk";
        that.bigbk22 = "";
        that.bigbk23 = "";
        that.bigbk24 = "";
        that.bigbk_t = false;
        that.bigbk_f = true;
        that.bigbk22_t = true;
        that.bigbk22_f = false;
        that.bigbk23_t = true;
        that.bigbk23_f = false;
        that.bigbk24_t = true;
        that.bigbk24_f = false;
      }
    },
    uploadimgurl(rul) {
      this.fmimg = rul;
    },
    uploadzipurl_again() {
      this.uploadziptype = true;
      this.uploadziptype_f = false;
    },
    uploadzipurl(url) {
      var that = this;
      that.uploadzipurls.push(url);
    },
    sousuowt() {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getSearchUserInfo",
        method: "post",
        data: {
          username: that.wt_mohu,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.wtuserall = res.data.result.list;
          var usernum = res.data.result.list.length % 4;
          var li = "";
          if (usernum == 2) {
            that.wtuserall.push(li, li);
          } else if (usernum == 3) {
            that.wtuserall.push(li);
          }
        }
      });
    },
    wt_xuanze(name, id) {
      this.wt_username = name;
      this.wt_userid = id;
    },
    typesmallid(id, url) {
      this.typesmallidnum = id;
      if (url == null) {
        this.fmimg;
      } else {
        this.fmimg = url;
      }
    },
    subxiangmu() {
      var that = this;
      if (this.ddnnumxm != "") {
        that.zhifuall = {
          type: true,
          money: that.moneyall,
          ddnnum: that.ddnnumxm,
          class_id: "1",
          faxiangmu: "88",
        };
      } else {
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/addTask",
          method: "post",
          data: {
            classid: that.typebigidnum, //大类
            task_type: that.typesmallidnum, //小类
            title: that.xm_name,
            titlepic: that.fmimg,
            tasktext: that.xm_gaishu,
            taskfile: that.uploadzipurls,
            money: that.xm_money,
            project: that.moneyall,
            phase: that.xm_xuxiutype,
            listed_time: that.xm_shangshi,
            start_time: that.startend[0],
            end_time: that.startend[1],
            istop: that.zhidingtypenum,
            demand_id: that.demand_id,
            undertake: that.undertake,
            etrust: that.wt_userid,
            survey_day: that.xm_dy,
            initial_day: that.xm_chubu,
            days: that.zhiding_num,
          },
        }).then((res) => {
          if (res.data.code == 1) {
            that.ddnnumxm = res.data.result;
            that.zhifuall = {
              type: true,
              money: that.moneyall,
              ddnnum: res.data.result,
              class_id: "1",
              faxiangmu: "88",
            };
          } else {
            this.$message(res.data.messages);
          }
        });
      }
      // return false;
    },
    handleClose(done) {
      var that = this;
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
          clearInterval(that.timer);
        })
        .catch((_) => {});
    },
    //////////////  任务书 ///////////////////////
    handleClick(tab, event) {
      //   console.log(tab, event);
    },
    nextpage(id) {
      var that = this;
      var pageall = that.dataall.length;
      console.log(pageall);
      if (id == pageall) {
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/addTaskDemand",
          method: "post",
          data: {
            task_id: "2",
            data: that.dataall,
          },
        }).then((res) => {
          if (res.data.code == 1) {
            that.$message("任务书填写完成");
            that.xiangmutype = true;
            that.renwushutype = false;
            that.demand_id = res.data.result;
            that.renwushutext = "修改任务书";
          }
        });
      } else {
        that.nextpagenum = Number(id) + 1;
        that.activeName = that.dataall[id].one;
        that.prvepagetype = true;
        if (that.nextpagenum == pageall) {
          that.nextpagetext = "提交任务书";
        }
      }
    },
    prvepage(id) {
      var that = this;
      var pageall = that.dataall.length;
      that.nextpagenum = Number(id) - 1;
      that.activeName = that.dataall[Number(id) - 2].one;
      that.nextpagetext = "下一页";
      if (id == 2) {
        that.prvepagetype = false;
      }
    },
    uploadzipurlrenwushu(url) {
      var that = this;
      console.log(this.$refs.hhh);
      that.$refs.hhh.value = url;
      console.log(this.$refs.hhh.value);
    },
    renwushuaxiso(classid) {
      var that = this;
      console.log(classid);
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getTaskPlan",
        method: "post",
        data: {
          classid,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          var www = res.data.result.question;
          //////////////
          for (var i = 0; i < www.length; i++) {
            that.activeName = www[0].one;
            for (var s = 0; s < www[i].two.length; s++) {
              if (
                www[i].two[s].input_type == "1" ||
                www[i].two[s].input_type == "2" ||
                www[i].two[s].input_type == "3" ||
                www[i].two[s].input_type == "4"
              ) {
                www[i].two[s].zhi = "";
              } else if (www[i].two[s].input_type == "0") {
                www[i].two[s].zhi = "";
                for (var k = 0; k < www[i].two[s].three.length; k++) {
                  www[i].two[s].three[k].zhi = [];
                }
              }
            }
          }
          //////////////
          that.dataall = www;
        }
      });
    },
  },
  created() {
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.typechange(22);
    this.renwushuaxiso(22);
    var id = that.$route.query.data;
    var name = that.$route.query.un;
    if (id) {
      this.wt_username = name;
      this.wt_userid = id;
    } else {
      //雇佣暂无
    }
  },
  watch: {
    uploadzipurls: function () {
      if (this.uploadzipurls != []) {
        this.uploadziptype = false;
        this.uploadziptype_f = true;
      } else {
        this.uploadziptype = true;
        this.uploadziptype_f = false;
      }
    },
    zhiding: function () {
      //是否置顶
      if (this.zhiding == false) {
        this.zhidingmoney = 0;
        this.zhiding_num = 0;
      } else {
        this.zhidingmoney = this.zhiding_num * this.zhiding_fei;
      }
    },
    xm_money: function () {
      //项目服务费
      this.fuwumoney = Number(this.xm_money) * 0.2;
      //项目金额
      this.moneyall =
        Number(this.xm_money) +
        Number(this.zhidingmoney) +
        Number(this.fuwumoney);
    },
    zhiding_num: function () {
      //项目天数
      if (this.zhiding == false) {
        this.zhidingmoney = 0;
        this.zhiding_num = 0;
      } else {
        this.zhidingmoney = this.zhiding_num * this.zhiding_fei;
      }
    },
    zhidingmoney: function () {
      //置顶金额
      this.moneyall =
        Number(this.xm_money) +
        Number(this.zhidingmoney) +
        Number(this.fuwumoney);
    },
    radiowt: function () {
      //是否委托
      if (this.radiowt == 1) {
        this.wttype = true;
        this.wt_username = "";
        this.wt_mohu = "";
        this.wtuserall = "";
      } else {
        this.wttype = false;
        this.wt_username = "";
        this.wt_mohu = "";
        this.wtuserall = "";
      }
    },
    wt_username: function () {
      if (this.wt_username != "") {
        this.wt_usernametype = true;
      } else {
        this.wt_usernametype = false;
      }
    },
    zhiding: function () {
      if (this.zhiding) {
        this.zhidingtypenum = 1;
      } else {
        this.zhidingtypenum = 0;
        this.zhiding_num = 0;
      }
    },
    wt_userid: function () {
      if (this.wt_userid != "") {
        this.undertake = 1;
      } else {
        this.undertake = 0;
      }
    },
  },
};
</script>
